<template>
	<Offer>
		<template #banner>
			<img slot="banner" v-if="mobile === false" src="../../../../../assets/images/banners/creative.png" alt="banner">
			<img slot="banner" v-else src="../../../../../assets/images/mobile/creative.png" alt="banner">
		</template>

		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/oferta">Wróć do spisu usług</router-link>
		</template>

		<template #small-title>
			<h4>PRAWO GOSPODARCZE</h4>
		</template>

		<template #title>
			<h2>Branża kreatywna</h2>
		</template>

		<template #text>
			<p>Naszych Klientów z branży kreatywnej wspieramy kompleksowo, w szerokim zakresie obsługi prawnej.
				Doradzamy w jaki sposób mogą zabezpieczyć swoje prawa, zapewniamy pomoc w przypadku naruszeń ich praw
				własności, oferując dochodzenie ich roszczeń na etapie przesądowym i sądowym. Świadczymy pomoc w trakcie
				zawierania przez nich umów, czy udzielania licencji. Weryfikujemy umowy przedstawione im przez
				kontrahentów, a także prowadzimy negocjacje w ich imieniu, mające na celu jak najlepsze zabezpieczenie
				ich interesów.</p>
		</template>

		<template #range>
			<h3>Zakres:</h3>
		</template>
		<template #list>
			<ul class="offer__list">
				<li class="offer__list-item">Zapewnienie stałej, kompleksowej obsługi,</li>
				<li class="offer__list-item">Negocjacja umów związanych z projektowaniem
					i wzornictwem, rejestracja wzorów,
				</li>
				<li class="offer__list-item">Media i reklama</li>
				<li class="offer__list-item">Reprezentowanie w sporach na etapie przesądowym
					oraz sądowym
				</li>
			</ul>
		</template>
	</Offer>
</template>

<script>
import Offer from '../Offer'
import { handleWith } from "@/mixins/globalMixins";

export default {
	components: {
		Offer
	},
	name: 'Industry',
	mixins: [handleWith],
}
</script>
